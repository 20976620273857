import _slicedToArray from "/home/circleci/alloy-sdk/example/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "/home/circleci/alloy-sdk/example/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function createCommonjsModule(fn, module) {
  return module = {
    exports: {}
  }, fn(module, module.exports), module.exports;
}

var strictUriEncode = function strictUriEncode(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (x) {
    return "%".concat(x.charCodeAt(0).toString(16).toUpperCase());
  });
};

var token = '%[a-f0-9]{2}';
var singleMatcher = new RegExp(token, 'gi');
var multiMatcher = new RegExp('(' + token + ')+', 'gi');

function decodeComponents(components, split) {
  try {
    // Try to decode the entire string first
    return decodeURIComponent(components.join(''));
  } catch (err) {// Do nothing
  }

  if (components.length === 1) {
    return components;
  }

  split = split || 1; // Split the array in 2 parts

  var left = components.slice(0, split);
  var right = components.slice(split);
  return Array.prototype.concat.call([], decodeComponents(left), decodeComponents(right));
}

function decode(input) {
  try {
    return decodeURIComponent(input);
  } catch (err) {
    var tokens = input.match(singleMatcher);

    for (var i = 1; i < tokens.length; i++) {
      input = decodeComponents(tokens, i).join('');
      tokens = input.match(singleMatcher);
    }

    return input;
  }
}

function customDecodeURIComponent(input) {
  // Keep track of all the replacements and prefill the map with the `BOM`
  var replaceMap = {
    '%FE%FF': "\uFFFD\uFFFD",
    '%FF%FE': "\uFFFD\uFFFD"
  };
  var match = multiMatcher.exec(input);

  while (match) {
    try {
      // Decode as big chunks as possible
      replaceMap[match[0]] = decodeURIComponent(match[0]);
    } catch (err) {
      var result = decode(match[0]);

      if (result !== match[0]) {
        replaceMap[match[0]] = result;
      }
    }

    match = multiMatcher.exec(input);
  } // Add `%C2` at the end of the map to make sure it does not replace the combinator before everything else


  replaceMap['%C2'] = "\uFFFD";
  var entries = Object.keys(replaceMap);

  for (var i = 0; i < entries.length; i++) {
    // Replace all decoded components
    var key = entries[i];
    input = input.replace(new RegExp(key, 'g'), replaceMap[key]);
  }

  return input;
}

var decodeUriComponent = function decodeUriComponent(encodedURI) {
  if (typeof encodedURI !== 'string') {
    throw new TypeError('Expected `encodedURI` to be of type `string`, got `' + typeof encodedURI + '`');
  }

  try {
    encodedURI = encodedURI.replace(/\+/g, ' '); // Try the built in decoder first

    return decodeURIComponent(encodedURI);
  } catch (err) {
    // Fallback to a more advanced decoder
    return customDecodeURIComponent(encodedURI);
  }
};

var splitOnFirst = function splitOnFirst(string, separator) {
  if (!(typeof string === 'string' && typeof separator === 'string')) {
    throw new TypeError('Expected the arguments to be of type `string`');
  }

  if (separator === '') {
    return [string];
  }

  var separatorIndex = string.indexOf(separator);

  if (separatorIndex === -1) {
    return [string];
  }

  return [string.slice(0, separatorIndex), string.slice(separatorIndex + separator.length)];
};

var queryString = createCommonjsModule(function (module, exports) {
  var isNullOrUndefined = function isNullOrUndefined(value) {
    return value === null || value === undefined;
  };

  function encoderForArrayFormat(options) {
    switch (options.arrayFormat) {
      case 'index':
        return function (key) {
          return function (result, value) {
            var index = result.length;

            if (value === undefined || options.skipNull && value === null || options.skipEmptyString && value === '') {
              return result;
            }

            if (value === null) {
              return [].concat(_toConsumableArray(result), [[encode(key, options), '[', index, ']'].join('')]);
            }

            return [].concat(_toConsumableArray(result), [[encode(key, options), '[', encode(index, options), ']=', encode(value, options)].join('')]);
          };
        };

      case 'bracket':
        return function (key) {
          return function (result, value) {
            if (value === undefined || options.skipNull && value === null || options.skipEmptyString && value === '') {
              return result;
            }

            if (value === null) {
              return [].concat(_toConsumableArray(result), [[encode(key, options), '[]'].join('')]);
            }

            return [].concat(_toConsumableArray(result), [[encode(key, options), '[]=', encode(value, options)].join('')]);
          };
        };

      case 'comma':
      case 'separator':
        return function (key) {
          return function (result, value) {
            if (value === null || value === undefined || value.length === 0) {
              return result;
            }

            if (result.length === 0) {
              return [[encode(key, options), '=', encode(value, options)].join('')];
            }

            return [[result, encode(value, options)].join(options.arrayFormatSeparator)];
          };
        };

      default:
        return function (key) {
          return function (result, value) {
            if (value === undefined || options.skipNull && value === null || options.skipEmptyString && value === '') {
              return result;
            }

            if (value === null) {
              return [].concat(_toConsumableArray(result), [encode(key, options)]);
            }

            return [].concat(_toConsumableArray(result), [[encode(key, options), '=', encode(value, options)].join('')]);
          };
        };
    }
  }

  function parserForArrayFormat(options) {
    var result;

    switch (options.arrayFormat) {
      case 'index':
        return function (key, value, accumulator) {
          result = /\[(\d*)\]$/.exec(key);
          key = key.replace(/\[\d*\]$/, '');

          if (!result) {
            accumulator[key] = value;
            return;
          }

          if (accumulator[key] === undefined) {
            accumulator[key] = {};
          }

          accumulator[key][result[1]] = value;
        };

      case 'bracket':
        return function (key, value, accumulator) {
          result = /(\[\])$/.exec(key);
          key = key.replace(/\[\]$/, '');

          if (!result) {
            accumulator[key] = value;
            return;
          }

          if (accumulator[key] === undefined) {
            accumulator[key] = [value];
            return;
          }

          accumulator[key] = [].concat(accumulator[key], value);
        };

      case 'comma':
      case 'separator':
        return function (key, value, accumulator) {
          var isArray = typeof value === 'string' && value.split('').indexOf(options.arrayFormatSeparator) > -1;
          var newValue = isArray ? value.split(options.arrayFormatSeparator).map(function (item) {
            return decode(item, options);
          }) : value === null ? value : decode(value, options);
          accumulator[key] = newValue;
        };

      default:
        return function (key, value, accumulator) {
          if (accumulator[key] === undefined) {
            accumulator[key] = value;
            return;
          }

          accumulator[key] = [].concat(accumulator[key], value);
        };
    }
  }

  function validateArrayFormatSeparator(value) {
    if (typeof value !== 'string' || value.length !== 1) {
      throw new TypeError('arrayFormatSeparator must be single character string');
    }
  }

  function encode(value, options) {
    if (options.encode) {
      return options.strict ? strictUriEncode(value) : encodeURIComponent(value);
    }

    return value;
  }

  function decode(value, options) {
    if (options.decode) {
      return decodeUriComponent(value);
    }

    return value;
  }

  function keysSorter(input) {
    if (Array.isArray(input)) {
      return input.sort();
    }

    if (typeof input === 'object') {
      return keysSorter(Object.keys(input)).sort(function (a, b) {
        return Number(a) - Number(b);
      }).map(function (key) {
        return input[key];
      });
    }

    return input;
  }

  function removeHash(input) {
    var hashStart = input.indexOf('#');

    if (hashStart !== -1) {
      input = input.slice(0, hashStart);
    }

    return input;
  }

  function getHash(url) {
    var hash = '';
    var hashStart = url.indexOf('#');

    if (hashStart !== -1) {
      hash = url.slice(hashStart);
    }

    return hash;
  }

  function extract(input) {
    input = removeHash(input);
    var queryStart = input.indexOf('?');

    if (queryStart === -1) {
      return '';
    }

    return input.slice(queryStart + 1);
  }

  function parseValue(value, options) {
    if (options.parseNumbers && !Number.isNaN(Number(value)) && typeof value === 'string' && value.trim() !== '') {
      value = Number(value);
    } else if (options.parseBooleans && value !== null && (value.toLowerCase() === 'true' || value.toLowerCase() === 'false')) {
      value = value.toLowerCase() === 'true';
    }

    return value;
  }

  function parse(input, options) {
    options = Object.assign({
      decode: true,
      sort: true,
      arrayFormat: 'none',
      arrayFormatSeparator: ',',
      parseNumbers: false,
      parseBooleans: false
    }, options);
    validateArrayFormatSeparator(options.arrayFormatSeparator);
    var formatter = parserForArrayFormat(options); // Create an object with no prototype

    var ret = Object.create(null);

    if (typeof input !== 'string') {
      return ret;
    }

    input = input.trim().replace(/^[?#&]/, '');

    if (!input) {
      return ret;
    }

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = input.split('&')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var param = _step.value;

        var _splitOnFirst = splitOnFirst(options.decode ? param.replace(/\+/g, ' ') : param, '='),
            _splitOnFirst2 = _slicedToArray(_splitOnFirst, 2),
            key = _splitOnFirst2[0],
            value = _splitOnFirst2[1]; // Missing `=` should be `null`:
        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters


        value = value === undefined ? null : ['comma', 'separator'].includes(options.arrayFormat) ? value : decode(value, options);
        formatter(decode(key, options), value, ret);
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    for (var _i = 0, _Object$keys = Object.keys(ret); _i < _Object$keys.length; _i++) {
      var _key = _Object$keys[_i];
      var _value = ret[_key];

      if (typeof _value === 'object' && _value !== null) {
        for (var _i2 = 0, _Object$keys2 = Object.keys(_value); _i2 < _Object$keys2.length; _i2++) {
          var k = _Object$keys2[_i2];
          _value[k] = parseValue(_value[k], options);
        }
      } else {
        ret[_key] = parseValue(_value, options);
      }
    }

    if (options.sort === false) {
      return ret;
    }

    return (options.sort === true ? Object.keys(ret).sort() : Object.keys(ret).sort(options.sort)).reduce(function (result, key) {
      var value = ret[key];

      if (Boolean(value) && typeof value === 'object' && !Array.isArray(value)) {
        // Sort object keys, not values
        result[key] = keysSorter(value);
      } else {
        result[key] = value;
      }

      return result;
    }, Object.create(null));
  }

  exports.extract = extract;
  exports.parse = parse;

  exports.stringify = function (object, options) {
    if (!object) {
      return '';
    }

    options = Object.assign({
      encode: true,
      strict: true,
      arrayFormat: 'none',
      arrayFormatSeparator: ','
    }, options);
    validateArrayFormatSeparator(options.arrayFormatSeparator);

    var shouldFilter = function shouldFilter(key) {
      return options.skipNull && isNullOrUndefined(object[key]) || options.skipEmptyString && object[key] === '';
    };

    var formatter = encoderForArrayFormat(options);
    var objectCopy = {};

    for (var _i3 = 0, _Object$keys3 = Object.keys(object); _i3 < _Object$keys3.length; _i3++) {
      var key = _Object$keys3[_i3];

      if (!shouldFilter(key)) {
        objectCopy[key] = object[key];
      }
    }

    var keys = Object.keys(objectCopy);

    if (options.sort !== false) {
      keys.sort(options.sort);
    }

    return keys.map(function (key) {
      var value = object[key];

      if (value === undefined) {
        return '';
      }

      if (value === null) {
        return encode(key, options);
      }

      if (Array.isArray(value)) {
        return value.reduce(formatter(key), []).join('&');
      }

      return encode(key, options) + '=' + encode(value, options);
    }).filter(function (x) {
      return x.length > 0;
    }).join('&');
  };

  exports.parseUrl = function (input, options) {
    return {
      url: removeHash(input).split('?')[0] || '',
      query: parse(extract(input), options)
    };
  };

  exports.stringifyUrl = function (input, options) {
    var url = removeHash(input.url).split('?')[0] || '';
    var queryFromUrl = exports.extract(input.url);
    var parsedQueryFromUrl = exports.parse(queryFromUrl);
    var hash = getHash(input.url);
    var query = Object.assign(parsedQueryFromUrl, input.query);
    var queryString = exports.stringify(query, options);

    if (queryString) {
      queryString = "?".concat(queryString);
    }

    return "".concat(url).concat(queryString).concat(hash);
  };
});
var queryString_1 = queryString.extract;
var queryString_2 = queryString.parse;
var queryString_3 = queryString.stringify;
var queryString_4 = queryString.parseUrl;
var queryString_5 = queryString.stringifyUrl;
var config = {
  iframe: 'https://alloy-test.z1.digital/'
};
var alloyId = 'alloy';
var alloyInitParams = {
  key: ''
};
var alloyCallback;
var iframeSrc;

var close = function close() {
  var element = document.getElementById(alloyId);

  if (element) {
    document.body.removeChild(element);
  }
};

var init = function init(_a) {
  var key = _a.key,
      entityToken = _a.entityToken,
      externalEntityId = _a.externalEntityId,
      evaluationData = _a.evaluationData,
      production = _a.production,
      maxEvaluationAttempts = _a.maxEvaluationAttempts,
      documents = _a.documents,
      selfie = _a.selfie;
  alloyInitParams.key = key;

  if (entityToken) {
    alloyInitParams.entityToken = entityToken;
  }

  if (externalEntityId) {
    alloyInitParams.externalEntityId = externalEntityId;
  }

  if (production) {
    alloyInitParams.production = production;
  }

  if (maxEvaluationAttempts) {
    alloyInitParams.maxEvaluationAttempts = maxEvaluationAttempts;
  }

  if (documents) {
    alloyInitParams.documents = documents;
  }

  if (selfie) {
    alloyInitParams.selfie = selfie;
  }

  iframeSrc = queryString.stringify(alloyInitParams) + "&" + (evaluationData && queryString.stringify(evaluationData));
  window.addEventListener('message', function (event) {
    if (event.origin !== 'https://alloy-test.z1.digital' || !alloyCallback) {
      return;
    }

    close();

    if (event.data !== 'close') {
      alloyCallback(event.data);
    }
  });
};

var open = function open(cb) {
  alloyCallback = cb;

  if (!alloyInitParams.key) {
    // eslint-disable-next-line no-console
    console.warn('The Alloy framework has not been initialized');
  } else {
    var alloyHeadStyles = document.getElementById('alloy-iframe-style');

    if (!alloyHeadStyles) {
      document.head.insertAdjacentHTML('beforeend', "<style id=\"alloy-iframe-style\">\n        @keyframes fade {\n          0%, 40% { opacity: 0 }\n          80% { opacity: 1 }\n        }\n        @media (max-width: 767px) {\n          .alloy-container {\n              height: 100vh;\n              width: 100vw;\n          }\n        }\n        .alloy-iframe {\n            animation: fade 1.5s linear;\n            border: 0;\n            opacity: 1;\n            height: 100%;\n            max-height: 100%;\n            width: 100%;\n        }\n        @media (min-width: 768px) {\n            .alloy-iframe {\n              border-radius: 1rem;\n              box-shadow: 0 10px 20px 0 rgba(63,83,92,0.3);\n              left: 50%;\n              max-height: 37.5rem;\n              overflow: scroll;\n              position: absolute;\n              top: 50%;\n              transform: translate(-50%, -50%);\n              width: 50rem;\n            }\n          }</style>");
    }

    var container = document.getElementById(alloyId);

    if (!container) {
      container = document.createElement('div');
      container.className = 'alloy-container';
      var alloyIframe = document.createElement('iframe');
      alloyIframe.id = 'alloyIframe';
      alloyIframe.src = config.iframe + "?" + iframeSrc;
      alloyIframe.allow = 'camera';
      alloyIframe.className = 'alloy-iframe';
      container.id = alloyId;
      document.body.appendChild(container);
      container.append(alloyIframe);
    }
  }
};

var alloy = {
  init: init,
  open: open,
  close: close
};
export default alloy;